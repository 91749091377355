
#treegrid button{
	background-color: transparent;
	border: none;
}

li {
	list-style: none;
}

input[type='checkbox'] {
	width: 20px;
	height: 20px;
}

.rct-title {
	font-size: large;
}

/* scroll di tutto card-body a sinistra */
#div_treegrid{
		overflow: auto;
		height: 400px;
}
/* rct-collapse */

/* .rct-node.rct-node-leaf {
	float:left;
   } */
   /* .rct-node.rct-node-parent {
	float:inline-start;
   } */